import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import '../styles/global.css'

const IndexPage = () => {
  return (
    <Layout pageTitle="Hi." >
      <p class="font-thin">My name is Connor. Welcome to my website, which I hope to be the first home on the internet for Generalists. You may have heard the expression "Jack of All Trades, Master of None". Traditional wisdom has lead us to believe that you must Specialize to be successful in this world. However, I believe that when you spread your interests wide, something new emerges that is greater than the sum of its parts. You are able to create connections that Specialists cannot.</p>

      
    </Layout>
  )
}

export default IndexPage