import * as React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import {
  container,
  heading,
  navLinks,
  navLinkItem,
  navLinkText,
  siteTitle,
  siteTitleText
} from './layout.module.css'


const Layout = ({ pageTitle, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const navLinkClassName = "hover:underline hover:text-white transition-all duration-300"
  
  return (
    <div class="container mx-auto px-40 py-8">
      <title>{pageTitle} | {data.site.siteMetadata.title}</title>
      <header class="my-1.5 text-white text-3xl font-medium">
        <Link to="/">
          Connor Van Cleave
        </Link>
      </header>
      <nav class="mb-32 py-4 border-b text-textSecondary border-border text-lg">
        <ul class="flex gap-4">
          {/* <li className={navLinkItem}>
            <Link to="/about" className={navLinkText}>
              About
            </Link>
          </li> */}
          <li>
            <Link to="/" className={navLinkClassName}>
              Home
            </Link>
          </li>
          <li >
            <Link to="/blog" className={navLinkClassName}>
              Blog
            </Link>
          </li>
          <li>
            <a href="https://twitter.com/ConnorVanCleave" target="_blank" className={navLinkClassName}>
              Twitter
            </a>
          </li>
        </ul>
      </nav>
      <main class="pt-2">
        <h1 class="text-white text-7xl font-bold mb-8">{pageTitle}</h1>
        <div class="text-2xl text-textPrimary font-thin">
          {children}
        </div>
      </main>
    </div>
  )
}

export default Layout